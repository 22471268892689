import { mapActions } from 'vuex'
import store from '../../../store'

export default{
    
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    model: {},
    show: false,
    showForm: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    
      headers: [
        { text: 'Código',       value: 'code'},
        { text: 'Nome',         value: 'name'},
        { text: 'Sigla',        value: 'initials'},
        { text: 'Digital',      value: 'digital',           type: "boolean", align: 'center'},
        { text: 'Professor',    value: 'has_teacher',       type: "boolean", align: 'center'},
        { text: 'CH Paga',      value: 'has_paid_workload', type: "boolean", align: 'center'},
        { text: 'Horário',      value: 'has_time_table',    type: "boolean", align: 'center'},
        { text: 'Ambiente',     value: 'has_ambience',      type: "boolean", align: 'center'},
        { text: 'Divisível',    value: 'is_divisible',      type: "boolean", align: 'center'},
        { text: 'Sobreposição', value: 'allow_overlap',     type: "boolean", align: 'center'},
        { text: 'Padrão',       value: 'default',           type: "boolean", align: 'center'},
        { text: 'Status',       value: 'active' },
        { text: 'Ações',        value: 'actions'},
    ]
    }),
    
    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o tipo de carga horária " + item_new.name + "?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
    },
    components: {
      
    },
    mounted(){
       
    },
    created() {
      
    },
    methods: {
      ...mapActions('workLoadType', ['ActionGetWorkLoadTypes', 'ActionDeleteWorkLoadType']),
        
      getData(){
      
        this.error = false;
        this.message = '';
        const params = this.params;
        this.loadingProgress = true;
        this.showForm = false;
        
        this.ActionGetWorkLoadTypes(params)
            .then((res) => {
                this.collections = res.data
                this.meta = res.meta
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDeleteWorkLoadType({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        }

  },

}
